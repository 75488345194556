

function ProjectDetails(props) {
    return (
      <div className="project-details">
        <h1>PROJECT: {props.name}</h1>
      </div>
    )
  }
  
  export default ProjectDetails